<template>
    <v-flex xs12 md12>
		<v-layout wrap>			
			<!-- IDNOTIFEQUIP -->
			<v-flex xs12 md5>							
				<pui-select
					id="idnotifequip"
					attach="idnotifequip"
					:label="$t('partidaequipamiento.idnotifequip')"
					toplabel
					clearable
					required
					reactive
					:disabled="formDisabled"
					v-model="model"
					modelName="vlupequipamiento"
					:itemsToSelect="[{ id: model.idnotifequip }]"
					:modelFormMapping="{ id: 'idnotifequip' }"
					itemValue="id"
					:itemText="item => `${item.id} - ${item.matricula} - ${item.tipoequipamiento}`"
					:order="{ matricula: 'asc' }"
					:fixedFilter="fixedFilterEquipamiento"
				></pui-select>							
			</v-flex>
			<!-- LUGARESTIBA -->
			<v-flex xs12 md4>						
				<pui-text-field
					:id="`lugarestiba-partidaequipamiento`"
					v-model="model.lugarestiba"
					:label="$t('partidaequipamiento.lugarestiba')"
					:disabled="formDisabled"
					:required="requiredLugarestiba"				
					toplabel
					maxlength="25"
				></pui-text-field>
			</v-flex>	
			<!-- NUMBULTOS -->
			<v-flex xs12 md1>
				<pui-number-field
					:id="`numbultos-partidaequipamiento`"
					v-model="model.numbultos"
					:label="$t('partidaequipamiento.numbultos')"
					:disabled="formDisabled"
					toplabel
					integer
					min="0"
					max="99999999"
				></pui-number-field>
			</v-flex>					
			
			<!-- PESONETO -->
			<v-flex xs12 md1>
				<pui-number-field
					:id="`pesoneto-partidaequipamiento`"
					v-model="model.pesoneto"
					:label="$t('partidaequipamiento.pesoneto')"
					:disabled="formDisabled"
					toplabel
					required
					decimals="3"
					maxlength="18"
				></pui-number-field>
			</v-flex>
		</v-layout>
	</v-flex>
</template>

<script>
export default {
	name: 'partidaequipamientoquickedit-form',
	
	data() {
		return {
			modelName: 'partidaequipamiento'
		};
	},
	props: {
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		parentModel: {
			type: Object,
			required: true
		}
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		},
		existenequipamientospartidas(deleteaction) {
			var divInfo = document.getElementById('partidaequipamiento_0_info');
			console.log("divInfo.innerHTML: " + divInfo.innerHTML);
			
			if(deleteaction){
				if(divInfo.innerHTML.startsWith('1', 4)){
					this.parentModel.existenequipamientospartidas = false;
				} else {
					this.parentModel.existenequipamientospartidas = true;
				}
			} else {
				if(divInfo.innerHTML.startsWith('0')){
					this.parentModel.existenequipamientospartidas = false;				
				} else {
					this.parentModel.existenequipamientospartidas = true;
				}
			}
		}
	},
	computed: {
		fixedFilterEquipamiento() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'idnotif', op: 'eq', data: this.parentModel.idnotif }]
					}
				],
				groupOp: 'and',
				rules: []
			};
		},
		requiredLugarestiba() {
			//Si la notificación tiene numescaent es obligatorio			
			if (this.parentModel.numescent) {				
				return true;
			}
			return false;
			
		}
	},
	created() {
	},
	mounted(){		
		
		this.existenequipamientospartidas(false);

		//cuando se borra fila, no resta el número de registros, se debe hacer a mano
		this.$puiEvents.$on(`onPui-deleteRow-dataTable-${this.modelName}`, () => {
			console.log('deleteRow');
			this.existenequipamientospartidas(true);
		});

		//cuando se crea una nueva fila
		this.$puiEvents.$on(`onPui-clearSelection-dataTable-${this.modelName}`, () => {
			console.log('clearSelection-dataTable');
			this.existenequipamientospartidas(false);
		});
	},
	destroyed() {
		this.$puiEvents.$off(`onPui-clearSelection-dataTable-${this.modelName}`);
		this.$puiEvents.$off(`onPui-deleteRow-dataTable-${this.modelName}`);
	}
}
</script>
